import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { getEditorColor, renderColor } from '../../util/ColorUtil';
import {
  isMobile,
  isTablet,
  isLaptop,
  isLinkAbsolute,
  isItUndefined,
  modifyLinks,
} from '../../util/DataUtil';
import { getObjectValueByMachineName } from '../../util/ObjectUtil';
import CtaBarButton from '../base/cta-bar-button';
import { Constants } from '../../util/Constants';
import Image from 'next/image';

const Map = dynamic(() => import('../map/map'));
const FacilityModal = dynamic(() => import('../modal/FacilityModal'));

const TemplateHero = (props) => {
  let dataSet = { ...props?.metroData, ...props?.facilityData };
  const latitude = props?.children?.field_location_latitude[0]?.value
    ? Number(
        getObjectValueByMachineName(
          props?.children?.field_location_latitude[0]?.value,
          dataSet
        )
      )
    : Number(props?.facilityData?.lat);
  const longitude = props?.children?.field_location_longitude[0]?.value
    ? Number(
        getObjectValueByMachineName(
          props?.children?.field_location_longitude[0]?.value,
          dataSet
        )
      )
    : Number(props?.facilityData?.lng);
  const [currentZoom, setCurrentZoom] = useState(
    props.children.field_map_zoom[0]?.value
      ? Number(props.children.field_map_zoom[0].value)
      : 9
  );
  const FacilityLocationMarker = ({ text }) => (
    <div className={`marker ${props?.regionColor}`}>{text}</div>
  );
  const ref = useRef(null);
  const [mobileFontSize, setMobileFontSize] = useState(0);
  const imageHeroType =
    props.children.field_hero_type[0]?.value === 'imageHero';
  const [heroLeftHeight, setHeroLeftHeight] = useState({});
  const [marketoTrigger, setMarketoTrigger] = useState(false);
  const showDash =
    (props?.children?.field_title[0]?.value || dataSet?.metroName
      ? true
      : false) &&
    (props?.children?.field_second_line[0]?.value ||
    props?.facilityData?.siteCodeName
      ? true
      : false) &&
    (props?.children?.field_third_line[0]?.value ||
    props?.facilityData?.nickName
      ? true
      : false);
  const emphasizedTextColor = getEditorColor(
    isItUndefined(props?.children?.field_description_long)
  );
  const [image, setImage] = useState('');

  const getMarginBottom = (width) => {
    if (!width) return;

    if (isTablet(width) && !imageHeroType) return 120;
    else if (isTablet(width) && imageHeroType) return 80;
    else if (isLaptop(width) && !imageHeroType) return 140;
    else if (isLaptop(width) && imageHeroType) return 120;

    return 120;
  };

  if (!imageHeroType && (!latitude || !longitude)) return null;

  useEffect(() => {
    setTimeout(() => {
      setImage(props?.children?.field_hero_image?.webimage);
      setHeroLeftHeight({
        height: ref?.current?.offsetHeight - getMarginBottom(props?.width),
      });
    }, 100);

    setMobileFontSize(props?.children?.field_mobile_font_size[0]?.value);

    return () => {
      setImage({});
    };
  }, [props]);

  const getMobileFontSize = (value, width) => {
    if (!value || !width) return;

    if (isMobile(width)) return `${value}px`;

    return '54px';
  };

  const renderTextLines = () => {
    if (imageHeroType) {
      return (
        <h1
          style={{
            fontSize: isMobile(props.width)
              ? getMobileFontSize(mobileFontSize, props.width)
              : null,
          }}
          className="hero-title"
        >
          <span>
            {props?.children?.field_title[0]?.value
              ? props?.children?.field_title[0].value
              : dataSet?.metroName}{' '}
          </span>
          <span>
            {props?.children?.field_second_line[0]?.value
              ? props?.children?.field_second_line[0].value
              : dataSet?.nickName}
          </span>
          <span>{isItUndefined(props.children.field_third_line)}</span>
        </h1>
      );
    }
    return (
      <h1
        style={{
          fontSize: isMobile(props?.width)
            ? getMobileFontSize(mobileFontSize, props.width)
            : null,
        }}
        className="hero-title"
      >
        <span>
          {props?.children?.field_title[0]?.value
            ? props?.children?.field_title[0].value
            : dataSet?.metroName}{' '}
        </span>
        {(props?.children?.field_second_line[0]?.value ||
          props?.facilityData?.siteCodeName) && (
          <span
            style={{
              fontSize: isMobile(props?.width)
                ? getMobileFontSize(mobileFontSize, props?.width)
                : null,
            }}
          >
            {props?.children?.field_second_line[0]?.value
              ? props?.children?.field_second_line[0]?.value
              : props?.facilityData?.siteCodeName}
            {showDash && ` —`}
          </span>
        )}
        <span
          style={{
            fontSize: isMobile(props?.width)
              ? getMobileFontSize(mobileFontSize, props?.width)
              : null,
          }}
        >
          {props?.children?.field_third_line[0]?.value
            ? props?.children?.field_third_line[0]?.value
            : props?.facilityData?.nickName}
        </span>
      </h1>
    );
  };

  return (
    <>
      <div className={`hero-banner ${props?.regionColor}`}>
        <div className="inner-container">
          <div
            ref={ref}
            className={
              imageHeroType && !isMobile(props?.width)
                ? `hero-left image`
                : 'hero-left'
            }
          >
            <div className="hero-child">
              {renderTextLines()}
              <span
                dangerouslySetInnerHTML={{
                  __html: modifyLinks(
                    getObjectValueByMachineName(
                      props?.children?.field_description_long[0]?.value,
                      dataSet
                    )
                  ),
                }}
                className={`hero-description ${emphasizedTextColor ? emphasizedTextColor : ''}`}
              ></span>
              {props?.children?.field_cta_bar_button_selection?.length > 0 && (
                <CtaBarButton
                  blockTypeClass={'hero'}
                  hasCtaArrow={true}
                  bgColor={renderColor(
                    isItUndefined(props?.children?.field_text_color),
                    props?.regionColor
                  )}
                  setMarketoTrigger={setMarketoTrigger}
                  data={
                    props?.children?.field_cta_bar_button_selection[0]
                      ?.cta_bar_button
                  }
                />
              )}
            </div>
          </div>
          <div
            style={{ ...heroLeftHeight }}
            className={
              imageHeroType && !isMobile(props.width)
                ? 'hero-right image'
                : 'hero-right'
            }
          >
            {imageHeroType && image ? (
              <Image
                src={image ? image : undefined}
                layout="fill"
                objectFit="cover"
                objectPosition={'center'}
              />
            ) : (
              <></>
            )}
            {!imageHeroType && (
              <Map
                blockTypeClass={'hero'}
                currentZoom={currentZoom}
                setCurrentZoom={setCurrentZoom}
                latitude={latitude}
                longitude={longitude}
                renderMarkers={
                  <FacilityLocationMarker
                    lat={latitude}
                    lng={longitude}
                    text={
                      props?.children?.field_second_line[0]?.value
                        ? props?.children?.field_second_line[0]?.value
                        : props?.facilityData?.siteCodeName
                    }
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      {marketoTrigger && (
        <FacilityModal
          lang={props?.lang}
          global={props?.global}
          setTrigger={setMarketoTrigger}
          trigger={marketoTrigger}
          marketoId={isItUndefined(
            props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button
              ?.field_marketo_blocks[0]?.marketo_form_facility_block_type
              .field_marketo_form_ids
          )}
        >
          {
            props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button
              ?.field_marketo_blocks[0]?.marketo_form_facility_block_type
          }
        </FacilityModal>
      )}
    </>
  );
};

export default TemplateHero;
